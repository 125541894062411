import * as React from "react";

import parse from 'html-react-parser';
import "../components/intervention.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
//import ball from "../images/anneau-gas.jpg"
import { StaticImage } from "gatsby-plugin-image"


const InterventionPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { featuredImage } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const { faq } = props.pageContext

    const children = [];
    if (faq.question1 != null) {
        children.push({ question: faq.question1, answer: faq.reponse1 })
    }
    if (faq.question2 != null) {
        children.push({ question: faq.question2, answer: faq.reponse2 })
    }
    if (faq.question3 != null) {
        children.push({ question: faq.question3, answer: faq.reponse3 })
    }
    if (faq.question4 != null) {
        children.push({ question: faq.question4, answer: faq.reponse4 })
    }
    if (faq.question5 != null) {
        children.push({ question: faq.question5, answer: faq.reponse5 })
    }
    if (faq.question6 != null) {
        children.push({ question: faq.question6, answer: faq.reponse6 })
    }
    if (faq.question7 != null) {
        children.push({ question: faq.question7, answer: faq.reponse7 })
    }
    if (faq.question8 != null) {
        children.push({ question: faq.question8, answer: faq.reponse8 })
    }
    if (faq.question9 != null) {
        children.push({ question: faq.question9, answer: faq.reponse9 })
    }
    if (faq.question10 != null) {
        children.push({ question: faq.question10, answer: faq.reponse10 })
    }
    const path = typeof window !== "undefined" 
    ? window.location.pathname.split("/").filter(Boolean).pop() 
    : "";
      console.log("paath",path); 
      console.log("paath",path === "acide-hyaluronique"); 

    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <script type="application/ld+json">
                    {JSON.stringify(children)}</script>
                    <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
                     <script>
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '8919602118131306');
                            fbq('track', 'PageView');
                        `}
                    </script>
</Helmet>    
    <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                    <img height="1" width="1" style={{ display: "none" }}
                        src="https://www.facebook.com/tr?id=8919602118131306&ev=PageView&noscript=1" />
                </noscript>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>
           
            {path === "sleeve-gastrectomie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/sleeve.webp" 
            className="img-fluid service-image" 
            alt="Sleeve gastrique en Tunisie - Chirurgie bariatrique pour une perte de poids durable" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
            {path === "anneau-gastrique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/anneau-gastrique.webp" 
            className="img-fluid service-image" 
            alt="Anneau gastrique en Tunisie - Chirurgie bariatrique efficace" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
            {path === "by-pass-gastrique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/by-pass-gastrique.webp" 
            className="img-fluid service-image" 
            alt="Bypass gastrique en Tunisie - Chirurgie bariatrique pour une perte de poids durable" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
              {path === "ballon-gastrique-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/ballon-gastrique.webp" 
            className="img-fluid service-image" 
            alt="Bypass gastrique en Tunisie - Chirurgie bariatrique pour une perte de poids durable" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
              {path === "implant-dentaire-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/implant dentaire.webp" 
            className="img-fluid service-image" 
            alt="Implant dentaire en Tunisie - Qualité, confort et prix abordables" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
              {path === "facette-dentaire-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/facettes dentaires.webp" 
            className="img-fluid service-image" 
            alt="Facettes dentaires en Tunisie - Correction et esthétique dentaire" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
              {path === "blanchiment-dents-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/blanchiment dentaire.webp" 
            className="img-fluid service-image" 
            alt="Blanchiment dentaire en Tunisie - Dents plus blanches et sourire éclatant" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 

{path === "gingivectomie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/gingivectomies.webp" 
            className="img-fluid service-image" 
            alt="Gingivectomie en Tunisie - Correction du sourire gingival" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
{path === "couronne-dentaire-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Couronne dentaire.webp" 
            className="img-fluid service-image" 
            alt="Couronne dentaire en Tunisie - Soins de qualité à prix abordable" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
{path === "lipovaser-ou-liposuccion-vaser" && (
    <div className="image-container">
        <StaticImage 
            src="../images/laser lipo.webp" 
            className="img-fluid service-image" 
            alt="Liposuccion Vaser en Tunisie - Technologie avancée pour un corps sculpté" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
{path === "abdominoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Abdominoplastie.webp" 
            className="img-fluid service-image" 
            alt="Abdominoplastie en Tunisie - Ventre plat et silhouette harmonieuse" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
{path === "mini-abdominoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Mini Abdominoplastie.webp" 
            className="img-fluid service-image" 
            alt="Mini abdominoplastie en Tunisie - Raffermissement du bas ventre" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
{path === "body-lift-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/body lift.webp" 
            className="img-fluid service-image" 
            alt="Body lift en Tunisie - Remodelage complet pour une silhouette harmonieuse" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
  {path === "lifting-des-bras-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Lifting des bras.webp" 
            className="img-fluid service-image" 
            alt="Lifting des bras en Tunisie - Brachioplastie pour raffermir la peau" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
  {path === "lifting-cuisses-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/lifting des cuisses.webp" 
            className="img-fluid service-image" 
            alt="Lifting des cuisses en Tunisie - Raffermissement et remodelage des jambes" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
  {path === "augmentation-des-mollets-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/moullets.webp" 
            className="img-fluid service-image" 
            alt="Augmentation des mollets en Tunisie - Implants et lipofilling pour un galbe harmonieux" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
  {path === "liposuccion-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Liposuccion en Tunisie.webp" 
            className="img-fluid service-image" 
            alt="Liposuccion en Tunisie - Élimination des graisses pour une silhouette affinée" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "augmentation-fesses-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/augmentation des fesses.webp" 
            className="img-fluid service-image" 
            alt="Augmentation des fesses en Tunisie - Lipofilling et implants pour un fessier galbé" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}  
   {path === "lipofilling-fesses-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/lipofilling fessier.webp" 
            className="img-fluid service-image" 
            alt="Lipofilling fessier en Tunisie - Remodelage naturel du fessier" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "bbl-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/BBL.webp" 
            className="img-fluid service-image" 
            alt="BBL en Tunisie - Lifting brésilien des fesses pour un fessier galbé" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}   
   {path === "lifting-des-fesses" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Lifting des fesses.webp" 
            className="img-fluid service-image" 
            alt="Lifting des fesses en Tunisie - Raffermissement et galbe naturel" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}  
   {path === "augmentation-mammaire-tunise" && (
    <div className="image-container">
        <StaticImage 
            src="../images/augmentation-mamaire.webp" 
            className="img-fluid service-image" 
            alt="Résultat avant/après réduction mammaire en Tunisie : silhouette harmonieuse" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "lifting-mammaire-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/lifting des seins.webp" 
            className="img-fluid service-image" 
            alt="Résultat d'un lifting mammaire en Tunisie : poitrine rehaussée et raffermie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "lipofilling-mammaire" && (
    <div className="image-container">
        <StaticImage 
            src="../images/lipofilling mamaire.webp" 
            className="img-fluid service-image" 
            alt="Lipofilling mammaire en Tunisie - Augmentation naturelle des seins sans prothèses" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "reduction-mammaire-tunise" && (
    <div className="image-container">
        <StaticImage 
            src="../images/reduction mamaire.webp" 
            className="img-fluid service-image" 
            alt="Réduction mammaire en Tunisie - Poitrine plus légère et harmonieuse" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "gynecomastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/gynécomastie.webp" 
            className="img-fluid service-image" 
            alt="Résultat avant/après chirurgie de la gynécomastie en Tunisie : torse plat et masculin" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "chirurgie-paupiere-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Chirurgie paupière tombante.webp" 
            className="img-fluid service-image" 
            alt="Blépharoplastie en Tunisie - Chirurgie des paupières pour un regard rajeuni" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "canthopexie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Canthopexie-en-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Canthopexie en Tunisie - Fox Eyes et regard en amande" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "chirurgie-menton-tunisie-3" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Chirurgie Menton galoche.webp" 
            className="img-fluid service-image" 
            alt="Génioplastie en Tunisie - Correction du menton pour un visage harmonieux" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "lifting-visage-tunisie-4" && (
    <div className="image-container">
        <StaticImage 
            src="../images/lifting visage.webp" 
            className="img-fluid service-image" 
            alt="Lifting du visage en Tunisie - Rajeunissement naturel et effet durable" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "mini-lifting-visage-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/mini lifting visage.webp" 
            className="img-fluid service-image" 
            alt="Mini lifting visage en Tunisie - Soft lift et effet naturel" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "otoplastie-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Chirurgie oreilles décollées.webp" 
            className="img-fluid service-image" 
            alt="Otoplastie en Tunisie - Correction des oreilles décollées" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "rhinoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/rhinoplastie-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Rhinoplastie en Tunisie - Remodelage du nez et harmonie faciale" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "lipofilling-du-visage-en-tunisie-un-effet-rajeunissant-et-naturel" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Lipofilling visage.webp" 
            className="img-fluid service-image" 
            alt="Lipofilling visage en Tunisie - Rajeunissement naturel et volume harmonieux" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "greffe-de-cheveux-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/greffe poils et cheveux.webp" 
            className="img-fluid service-image" 
            alt="Résultat avant-après d’une greffe de cheveux en Tunisie : densité capillaire retrouvée" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "greffe-de-barbe-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/greffe de barbe.webp" 
            className="img-fluid service-image" 
            alt="Greffe de barbe en Tunisie - Résultat naturel et homogène" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "greffe-et-implant-de-sourcils" && (
    <div className="image-container">
        <StaticImage 
            src="../images/greffe de sourcils.webp" 
            className="img-fluid service-image" 
            alt="Greffe de sourcils en Tunisie - Implants pour un regard intense" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "nymphoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/nymphoplastie-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Nymphoplastie en Tunisie - Réduction des petites lèvres pour un confort intime" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "vaginoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/vaginoplastie-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Vaginoplastie en Tunisie - Rajeunissement et réparation intime" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "hymenoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/hymenoplastie-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Hyménoplastie en Tunisie - Reconstruction de l’hymen en toute discrétion" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "penoplastie-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/pemoplastie.webp" 
            className="img-fluid service-image" 
            alt="Pénoplastie en Tunisie - Augmentation du pénis en toute sécurité" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "biopsie-testiculaire-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/biopsie testiculaire.webp" 
            className="img-fluid service-image" 
            alt="Résultat d’une biopsie testiculaire en Tunisie : diagnostic et extraction de spermatozoïdes" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "fecondatio-in-vitro-tunisie-fiv" && (
    <div className="image-container">
        <StaticImage 
            src="../images/fécondation invitro.webp" 
            className="img-fluid service-image" 
            alt="Processus de fécondation in vitro en Tunisie : union de l’ovule et du spermatozoïde en laboratoire" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "insemination-artificielle-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/insemination Artificiele.webp" 
            className="img-fluid service-image" 
            alt="Insémination artificielle en Tunisie : technique de procréation médicalement assistée (PMA)" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "acide-hyaluronique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/insemination Artificiele.webp" 
            className="img-fluid service-image" 
            alt="Insémination artificielle en Tunisie : technique de procréation médicalement assistée (PMA)" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}

           <div className="container mt-4 pt-4">
                <div className="row">

                    {childs && childs.map((child, i) => {
              if (child.title == "Augmentation Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/augmentation-mammaire.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            
            if (child.title == "Lifting Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lifting-seins.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Lipofilling Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lipofilling.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Réduction Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/reduction.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
         if (child.title == "Acide Hyaluronique") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/acide-hylaronique.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "La Toxine Botulique ou Bot-ox") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/botox.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Traitement Laser") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/trait-laser.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Mesobotox ou Microbotox") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/mesobotox.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "PRP Tunisie") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/PRP.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Rhinoplastie Médicale en Tunisie") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/rhinoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Les Techniques d’Amincissement") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/amincissement.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Fils Tenseurs") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/fils-tenseurs.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }
                                 if (child.title == "Mésothérapie") {
                                     return (
         
                                         <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                             <div className="card h-100" >
         
                                                 <Link to={child.uri} className="link-interv">
                                                     <StaticImage src="../images/mesotherapie.webp" className="card-img-top img-fluid" alt={child.title} />
                                                 </Link>
                                                 <div className="card-body card-body-intervention   w-100">
                                                     <Link to={child.uri} className="link-interv">
                                                         <span className="text-center card-body-title ">{child.title}</span>
                                                     </Link>
         
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 }

                                                    
                                                
                                                                 
                                                                  
                    })

                    }

                </div>
            </div>

            <div className="container-fluid pt-2 blog-single">
                <div className="container ">
                    <div className="row">
                        {content && parse(content)}
                        {(() => {
                                                const options = [];
                                                if (faq.question1 != null) {
                                                    options.push(<h2>FAQ</h2>)
                                                }
                                                const options1 = [];

                                                if (faq.question1 != null) {
                                                    options1.push(
                                                        <div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                            <h3 itemprop="name">  {faq.question1}</h3>
                                                            <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                                <div itemprop="text">
                                                                    {faq.reponse1}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if (faq.question2 != null) {

                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question2}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse2}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question3 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question3}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse3}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question4 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question4}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse4}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question5 != null) {

                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question5}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse5}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question6 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question6}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse6}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question7 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question7}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse7}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question8 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question8}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse8}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question9 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question9}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse9}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question10 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question10}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse10}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                return [options, options1];
                                            })()}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default InterventionPage
